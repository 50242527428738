<template>
   <div class="content" autofocus>
                    

                    <!-- Start Content-->
                    <!-- Start Content-->
                    <div v-if="loading == false" class="container-fluid" style="margin-top:-56px;">
                        
                        <!-- start page title -->
                        
                        <!-- end page title --> 

                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row mb-2">
                                            <div class="col-xl-8">
                                                <form class="row gy-2 gx-2 align-items-center justify-content-xl-start justify-content-between">
                                                    <div class="col-auto">
                                                        <div class="text-xl-end mt-xl-0 mt-2">
                                                            <button type="button" @click="$router.push({name: 'client-create'})" class="btn btn-outline-info mb-2 me-2"><i class="uil uil-plus-circle"></i> Add Client</button>
                                                          
                                                        </div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <div class="d-flex align-items-center">
                                                            <label for="status-select" class="me-2">Status</label>
                                                            <select ref="select" class="form-select" id="status-select">
                                                                <option selected="">Choose...</option>
                                                                <option value="1">Paid</option>
                                                                <option value="2">Awaiting Authorization</option>
                                                                <option value="3">Payment failed</option>
                                                                <option value="4">Cash On Delivery</option>
                                                                <option value="5">Fulfilled</option>
                                                                <option value="6">Unfulfilled</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form>                            
                                            </div>
                                            
                                        </div>
                
                                        <div class="table-responsive">
                                            <table class="table table-centered mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th style="width: 12px;">
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck1">
                                                                <label class="form-check-label" for="customCheck1">&nbsp;</label>
                                                            </div>
                                                        </th>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th style="width: 125px;">Address</th>
                                                        <th>Manager</th>
                                                        <th>Phone</th>
                                                        <th style="width: 100px;">Last Visit</th>
                                                        <th style="width: 100px;">Last Order</th>
                                                        <th style="width: 100px;">AR</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="i in clientList" :key="i.id">
                                                        <td>
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck2">
                                                                <label class="form-check-label" for="customCheck2">&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name: 'client-view', params: {slug: i.slug}}" class="text-body fw-bold">
                                                                #{{i.id}}
                                                            </router-link> 
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name: 'client-view', params: {slug: i.slug}}" class="text-body fw-bold">
                                                            {{ i.name }}
                                                            </router-link>
                                                        </td>
                                                        <td>
                                                            {{ i.address }}, {{ i.city }}, {{i.state}}
                                                        </td>
                                                        <td>
                                                            {{i.manager}}
                                                        </td>
                                                        <td>
                                                            {{i.phone}}
                                                        </td>
                                                        <td>
                                                            June 05 2021
                                                        </td>
                                                        <td>
                                                            June 01 2021
                                                        </td>
                                                        <td>
                                                            <span class=""></span>
                                                        </td>
                                                    </tr>
                                                    
                                                  
                                                  


                                                  
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> <!-- end card-body-->
                                </div> <!-- end card-->
                            </div> <!-- end col -->
                        </div>
                        <!-- end row --> 
                        
                    </div>
                    <div v-else class="container-fluid" style="display:flex;align-items:center;justify-content:center;">
                        <span style="top: 42%;position:absolute;">
                            <div class="spinner-border" role="status">
                            
                            </div>
                        </span>
                    </div>

                </div> <!-- content --> 
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            loading: false,
            clientList: '',
        }
    },
    created() {
        this.fetchClients();
        
    },
    methods: {
        fetchClients() {
            this.loading = true;
            axios.get('/api/fetchuserclient?query='+localStorage.getItem('access_token')).then(resp => {
                this.clientList = resp.data.success.data;
                console.log(resp.data);
                this.loading = false;
            });
        }
    }
    
}
</script>