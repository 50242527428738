<template>
    <div class="content">
                   

                    <!-- Start Content-->
                    <div v-if="loading == false" class="container-fluid" style="margin-top:-56px;">
                        
                        <!-- start page title -->
                        <div class="row">
                            <div class="col-12">
                                <div class="page-title-box">
                                    
                                    <h4 class="page-title">#{{client.id}}</h4>
                                </div>
                            </div>
                        </div>     
                        <!-- end page title --> 

                        <div class="row">
                            <div class="col-xxl-12 col-lg-12">
                                <!-- project card -->
                                <div class="card d-block">
                                    <div class="card-body">
                                        <div class="dropdown float-end">
                                            <a href="#" class="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="uil uil-ellipsis-h"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end">
                                                <!-- item-->
                                                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-pencil me-1"></i>Edit</a>
                                                <!-- item-->
                                                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-delete me-1"></i>Delete</a>
                                                <!-- item-->
                                                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-email-outline me-1"></i>Invite</a>
                                                <!-- item-->
                                                <a href="javascript:void(0);" class="dropdown-item"><i class="mdi mdi-exit-to-app me-1"></i>Leave</a>
                                            </div>
                                        </div>
                                        <!-- project title-->
                                        <h3 class="mt-0">
                                            {{client.name}}
                                        </h3>
                                        <div class="badge bg-secondary text-light mb-3">Prospect</div>

                                        <div class="row mt-2">
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <h5>Manager</h5>
                                                    <p>{{client.manager}}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <h5>Address</h5>
                                                    <p>{{client.address}}
                                                        {{client.city}}, {{client.state}} {{client.zip}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <h5>Accounts Recieval</h5>
                                                    <p>$15,800</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <h5>Contact</h5>
                                                    <p>{{client.phone}}</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <h5>Zone</h5>
                                                    <p>BK - 12</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <h5>Sales Rep</h5>
                                                    <p>Jordan </p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <h5>Last Visit</h5>
                                                    <p>June 8, 2021</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <h5>Last Order</h5>
                                                    <p>June 2, 2021</p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-2">
                                                    <h5>Sales Total</h5>
                                                    <p>$124,000</p>
                                                </div>
                                            </div>
                                        </div>

                                        

                                    </div> <!-- end card-body-->
                                    
                                </div> <!-- end card-->
                                <carousel :items="3" :margin="30" :nav="false" class="mt-4">
                                    <div class="card">
                                        <img class="card-img-top" src="https://cdn.pepperi.com/WrntyImages/7791615/PortfolioItems/1/48497241_1_200x200.jpg?ft=1" style="height:175px;width:auto;object-fit:cover;" alt="project image cap">
                                        <div class="card-body">Product 1 - 12x36oz <br>
                                            <small class="text-muted">1,200 Units</small>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <img class="card-img-top" src="https://cdn.pepperi.com/WrntyImages/7791615/PortfolioItems/3/50714863_2_200x200.jpg?ft=1" style="height:175px;width:auto;object-fit:cover;" alt="project image cap">
                                        <div class="card-body">Product 2 - 11x36oz <br>
                                            <small class="text-muted">1,00 Units</small>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <img class="card-img-top" src="https://cdn.pepperi.com/WrntyImages/7791615/PortfolioItems/9/50715059_2_200x200.jpg?ft=1" style="height:175px;width:auto;object-fit:cover;" alt="project image cap">
                                        <div class="card-body">Product 3 <br>
                                            <small class="text-muted">900 Units</small>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <img class="card-img-top" src="https://images.unsplash.com/photo-1543158181-1274e5362710?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80" style="height:175px;width:auto;object-fit:cover;" alt="project image cap">
                                        <div class="card-body">Product 4 - 12x36oz <br>
                                            <small class="text-muted">600 Units</small>
                                        </div>
                                    </div>
                                </carousel>
                                <div class="card mt-4">
                                    <div class="table-responsive">
                                            <table class="table table-centered mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th style="width: 20px;">
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck1">
                                                                <label class="form-check-label" for="customCheck1">&nbsp;</label>
                                                            </div>
                                                        </th>
                                                        <th>Order ID</th>
                                                        <th>Date</th>
                                                        <th>Client</th>
                                                        <th style="width: 185px;">Address</th>
                                                        <th>Order Status</th>
                                                        <th>Total</th>
                                                        <th style="width: 125px;">AR</th>
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck2">
                                                                <label class="form-check-label" for="customCheck2">&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name:'Order'}" class="text-body fw-bold">#BM9708
                                                            </router-link> 
                                                        </td>
                                                        <td>
                                                            August 05<small class="text-muted">10:29 PM</small>
                                                        </td>
                                                        <td>
                                                            Great Company Long Name LLC
                                                        </td>
                                                        <td>
                                                            79 N 11th St, Brooklyn, NY | BK - 15
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-warning-lighten" style="background-color:rgba(0,0,0,0.2);color:rgba(0,0,0,0.8)">Start</span></h5>
                                                        </td>
                                                        <td>
                                                            $12,000
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-info-lighten">Unpaid</span></h5>
                                                        </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck2">
                                                                <label class="form-check-label" for="customCheck2">&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name:'Order'}" class="text-body fw-bold">#BM9708
                                                            </router-link> 
                                                        </td>
                                                        <td>
                                                            August 05<small class="text-muted">10:29 PM</small>
                                                        </td>
                                                        <td>
                                                            Great Company Long Name LLC
                                                        </td>
                                                        <td>
                                                            79 N 11th St, Brooklyn, NY
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-warning-lighten">Processing</span></h5>
                                                        </td>
                                                        <td>
                                                            $12,000
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-info-lighten">Unpaid</span></h5>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck2">
                                                                <label class="form-check-label" for="customCheck2">&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name:'Order'}" class="text-body fw-bold">#BM9708
                                                            </router-link> 
                                                        </td>
                                                        <td>
                                                            August 05<small class="text-muted">10:29 PM</small>
                                                        </td>
                                                        <td>
                                                            Great Company Long Name LLC
                                                        </td>
                                                        <td>
                                                            79 N 11th St, Brooklyn, NY
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-warning-lighten">Processing</span></h5>
                                                        </td>
                                                        <td>
                                                            $12,000
                                                        </td>
                                                        <td>
                                                            <span class="badge badge-success-lighten">Paid</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck2">
                                                                <label class="form-check-label" for="customCheck2">&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name:'Order'}" class="text-body fw-bold">#BM9708
                                                            </router-link> 
                                                        </td>
                                                        <td>
                                                            August 05<small class="text-muted">10:29 PM</small>
                                                        </td>
                                                        <td>
                                                            Great Company Long Name LLC
                                                        </td>
                                                        <td>
                                                            79 N 11th St, Brooklyn, NY
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-info-lighten">Shipped</span></h5>
                                                        </td>
                                                        <td>
                                                            $12,000
                                                        </td>
                                                        <td>
                                                            <span class="badge badge-success-lighten">Paid</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck2">
                                                                <label class="form-check-label" for="customCheck2">&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name:'Order'}" class="text-body fw-bold">#BM9708
                                                            </router-link> 
                                                        </td>
                                                        <td>
                                                            August 05<small class="text-muted">10:29 PM</small>
                                                        </td>
                                                        <td>
                                                            Great Company Long Name LLC
                                                        </td>
                                                        <td>
                                                            79 N 11th St, Brooklyn, NY
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-info-lighten">Shipped</span></h5>
                                                        </td>
                                                        <td>
                                                            $12,000
                                                        </td>
                                                        <td>
                                                            <span class="badge badge-success-lighten">Paid</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck2">
                                                                <label class="form-check-label" for="customCheck2">&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name:'Order'}" class="text-body fw-bold">#BM9708
                                                            </router-link> 
                                                        </td>
                                                        <td>
                                                            August 05<small class="text-muted">10:29 PM</small>
                                                        </td>
                                                        <td>
                                                            Great Company Long Name LLC
                                                        </td>
                                                        <td>
                                                            79 N 11th St, Brooklyn, NY
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-success-lighten">Delivered</span></h5>
                                                        </td>
                                                        <td>
                                                            $12,000
                                                        </td>
                                                        <td>
                                                            <span class="badge badge-success-lighten">Paid</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck2">
                                                                <label class="form-check-label" for="customCheck2">&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name:'Order'}" class="text-body fw-bold">#BM9708
                                                            </router-link> 
                                                        </td>
                                                        <td>
                                                            August 05<small class="text-muted">10:29 PM</small>
                                                        </td>
                                                        <td>
                                                            Great Company Long Name LLC
                                                        </td>
                                                        <td>
                                                            79 N 11th St, Brooklyn, NY
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-success-lighten">Delivered</span></h5>
                                                        </td>
                                                        <td>
                                                            $12,000
                                                        </td>
                                                        <td>
                                                            <span class="badge badge-success-lighten">Paid</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck2">
                                                                <label class="form-check-label" for="customCheck2">&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name:'Order'}" class="text-body fw-bold">#BM9708
                                                            </router-link> 
                                                        </td>
                                                        <td>
                                                            August 05<small class="text-muted">10:29 PM</small>
                                                        </td>
                                                        <td>
                                                            Great Company Long Name LLC
                                                        </td>
                                                        <td>
                                                            79 N 11th St, Brooklyn, NY
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-success-lighten">Delivered</span></h5>
                                                        </td>
                                                        <td>
                                                            $12,000
                                                        </td>
                                                        <td>
                                                            <span class="badge badge-success-lighten">Paid</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck2">
                                                                <label class="form-check-label" for="customCheck2">&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name:'Order'}" class="text-body fw-bold">#BM9708
                                                            </router-link> 
                                                        </td>
                                                        <td>
                                                            August 05<small class="text-muted">10:29 PM</small>
                                                        </td>
                                                        <td>
                                                            Great Company Long Name LLC
                                                        </td>
                                                        <td>
                                                            79 N 11th St, Brooklyn, NY
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-success-lighten">Delivered</span></h5>
                                                        </td>
                                                        <td>
                                                            $12,000
                                                        </td>
                                                        <td>
                                                            <span class="badge badge-success-lighten">Paid</span>
                                                        </td>
                                                    </tr>
                                                    
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                </div>
                               
                                <!-- end card-->
                            </div> <!-- end col -->

                            
                        </div>
                        <!-- end row -->
                        
                    </div> <!-- container -->
                    <div v-else class="container-fluid" style="display:flex;align-items:center;justify-content:center;">
                        <span style="top: 42%;position:absolute;">
                            <div class="spinner-border" role="status">
                            
                            </div>
                        </span>
                    </div>

                </div> <!-- content -->
</template>
<script>
import carousel from 'vue-owl-carousel';
import axios from 'axios';

export default {
    components: {
        carousel,
    },
    data() {
        return {
            loading: false,
            client: '',
        }
    },
    created() {
        this.fetchClient();
    },
    methods: {
        fetchClient() {
            this.loading = true;
            axios.get('/api/fetchclient?query='+ this.$route.params.slug)
            .then(resp => {
            console.log(resp.data.client);
            this.client = resp.data.client;
            this.loading = false;
          });
        }
    }
}
</script>