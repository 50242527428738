<template>
    <div class="content">
                    <!-- Topbar Start -->
                   
                    <!-- end Topbar -->

                    <!-- Start Content-->
                    <div v-if="loading == false" class="container-fluid" style="margin-top:-50px;">
                        
                        <!-- start page title -->
                        <div class="row">
                            <div class="col-12">
                                <div class="page-title-box">
                                    <div class="page-title-right">
                                       
                                    </div>
                                    
                                </div>
                            </div>
                        </div>     
                        <!-- end page title --> 

                        <div class="row justify-content-center">
                            <div class="col-lg-7 col-md-10 col-sm-11">
        
                                <div class="horizontal-steps mt-4 mb-4 pb-5" id="tooltip-container">
                                    <div class="horizontal-steps-content">
                                        <div class="step-item">
                                            <span data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="bottom" title="20/08/2018 07:24 PM">Order Placed</span>
                                        </div>
                                        <div class="step-item current">
                                            <span data-bs-container="#tooltip-container" data-bs-toggle="tooltip" data-bs-placement="bottom" title="21/08/2018 11:32 AM">Packed</span>
                                        </div>
                                        <div class="step-item">
                                            <span>Shipped</span>
                                        </div>
                                        <div class="step-item">
                                            <span>Delivered</span>
                                        </div>
                                    </div>
        
                                    <div class="process-line" style="width: 33%;"></div>
                                </div>
                            </div>
                        </div>
                        <!-- end row -->    
                        
                        
                        <div class="row">
                            <!-- Orders Table -->
                            <div class="col-lg-8">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="header-title mb-3">Items from Order #{{order.id}}</h4>
            
                                        <div class="table-responsive">
                                            <table class="table table-borderless table-centered mb-0">
                                                <thead class="table-light">
                                                <tr>
                                                                <th>Product</th>
                                                                <th>Price</th>
                                                                <th>Quantity</th>
                                                                <th>Total</th>
                                                                <th style="width: 50px;"></th>
                                                            </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="i in order.products" :key="i">
                                                    <td>
                                                        <img src="https://images.unsplash.com/photo-1543158181-1274e5362710?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80" alt="contact-img"
                                                            title="contact-img" class="rounded me-3" height="64" />
                                                        <p class="m-0 d-inline-block align-middle font-16">
                                                            <a href="apps-ecommerce-products-details.html"
                                                                class="text-body">{{i.title}}</a>
                                                            <br>
                                                            <small class="me-4"><b>{{i.packaging}}:</b> {{i.size}} </small>
                                                           
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {{$parent.formatCurrency(i.price)}}
                                                    </td>
                                                    <td style="text-align:center;">
                                                        {{i.pivot.quantity}}
                                                    </td>
                                                    <td>
                                                        {{$parent.formatCurrency(i.price * i.pivot.quantity)}}
                                                    </td>
                                                    <td>
                                                       
                                                    </td>
                                                </tr>
                                                
                                                </tbody>
                                            </table>
                                            <table class="mt-2 table mb-0">
                                                <thead class="table-light">
                                                <tr>
                                                    
                                                </tr>
                                                </thead>
                                                <tbody>
                                                
                                                <tr style="border-bottom:0px solid white;">
                                                    <th style="text-align:end;">Total :</th>
                                                    <th style="text-align:end;padding-right:40px;">{{$parent.formatCurrency(order.total)}}</th>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- end table-responsive -->
            
                                    </div>
                                </div>
                               
                            </div> <!-- end col -->
        
                            <div class="col-lg-4">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="header-title mb-3">Shipping Information</h4>

                                        <h5>{{order.client.name}}</h5>
                                        
                                        
                                        <address class="mt-2 mb-0 font-14 address-lg">
                                            795 Folsom Ave, Suite 600<br>
                                            San Francisco, CA 94107<br>
                                            
                                            <div class="pt-2">
                                                <abbr title="Phone">P:</abbr> (123) 456-7890 <br/>
                                                <abbr title="Mobile">M:</abbr> (+01) 12345 67890
                                            </div>
                                            
                                        </address>
            
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="header-title mb-3">Order Details</h4>

                                        <ul class="list-unstyled mb-0">
                                            <li>
                                                <p class="mb-2"><span class="fw-bold me-2">Date Made:</span> 06/12 10:00<small>am</small></p>
                                                <p class="mb-2"><span class="fw-bold me-2">Sales Rep:</span> Jeremy Pierce</p>
                                                <p class="mb-2"><span class="fw-bold me-2">Ordered By:</span> {{order.orderedby}}</p>
                                                <p class="mb-2"><span class="fw-bold me-2">Sugg. Delivery:</span> {{order.deliverby}}</p>
                                                <p class="mb-2"><span class="fw-bold me-2">Area Code:</span> BK-12</p>
                                                <p class="mb-0"><span class="fw-bold me-2">Note:</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                            </li>
                                        </ul>
            
                                    </div>
                                </div>
                                
                            </div> <!-- end col -->
                        </div>
                        <!-- end row -->
        
        
                        
                    </div> <!-- container -->
                    <div v-else class="container-fluid" style="display:flex;align-items:center;justify-content:center;">
                        <span style="top: 42%;position:absolute;">
                            <div class="spinner-border" role="status">
                            
                            </div>
                        </span>
                    </div>

                </div> <!-- content -->
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            loading: false,
            order: '',
        }
    },
    created() {
        this.fetchOrder();
    },
    methods: {
        fetchOrder() {
            this.loading = true;
            axios.get('/api/fetchorder?query='+ this.$route.params.id)
            .then(resp => {
            console.log(resp.data.order);
            this.order = resp.data.order;
            this.loading = false;
          });
        }
    }
}
</script>