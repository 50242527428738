<template>
<section class="section-half-rounded bg-cover bg-size--cover" style="position:absolute;top:0px;left:0px;right:0px;background-image:url(https://preview.webpixels.io/quick-website-ui-kit/assets/img/backgrounds/img-3.jpg)">
    <div class="container-fluid d-flex flex-column">
        <div class="row align-items-center min-vh-100">
            <div class="col-md-6 col-lg-5 col-xl-4 mx-auto">
                <div class="card shadow-lg border-0 mb-0">
                    <div class="card-body py-5 px-sm-5">
                        <div>
                            <div class="mb-5 text-center">
                                <h6 class="h3 mb-1">Login</h6>
                                <p class="text-muted mb-0">Sign in to your account to continue.</p>
                            </div>
                            <span class="clearfix">
                            </span>
                            
                                <div class="form-group mb-2">
                                    <label class="form-control-label">Email address</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" style="height:100%;">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                            </span>
                                        </div>
                                        <input v-model="username" type="email" class="form-control" id="input-email" placeholder="name@example.com">
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div>
                                            <label class="form-control-label">Password</label>
                                        </div>
                                        
                                    </div>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" style="height:100%;">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <input v-model="password" type="password" class="form-control" id="input-password" placeholder="Password">
                                    </div>
                                </div>
                                
                                <div class="d-grid mt-4 mb-0 text-center">
                                    <button v-if="loading == false" @click="logStateIn" class="btn btn-primary" type="submit"><i class="uil uil-signout"></i> Log In </button>
                                    <div v-else class="spinner-border" style="margin: 0 auto;" role="status">
                                        
                                    </div>
                                </div>
                            
                           
                            <div class="row">
                               
                            </div>
                            <div class="mt-4 text-center">
                                <small>Not registered?</small> 
                                <a href="register-overlay.html" class="small font-weight-bold">Create account</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            loading: false,
            credentials: {},
            serverError: '',
            username: '',
            password: '',
        }
    },
    methods: {
        signIn() {
            console.log(this.credentials);
            axios.get('/sanctum/csrf-cookie').then(resp=> {
                
                axios.post('/login', {
                    email: this.credentials.email,
                    password: this.credentials.password,
                }).then(resp2 => {
                    axios.get('/api/user').then(data => {
                        console.log(data);
                    });
                });
            });
        },
        logStateIn() {
            this.loading = true;
            this.$store.dispatch('retrieveToken', {
            email: this.username,
            password: this.password
            })
            .then(response => {
                
                this.$router.push({ name: 'Home' });
                this.loading = false;
            })
            .catch(error => {
                this.loading = false;
                console.log(error);
                
            });
        },
    }
    
}
</script>