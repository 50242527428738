<template>
<div class="col-md-6 col-lg-3 col-xxl-2" style="padding: 0 4px;">
                                <!-- project card -->
                                <div class="card d-block">
                                    <!-- project-thumbnail -->
                                    <img class="card-img-top" :src="product.img_src" alt="project image cap">
                                    <div class="card-img-overlay">
                                        <div class="badge bg-secondary text-light p-1">New</div>
                                    </div>

                                    <div class="card-body position-relative" style="margin-top:-10px;">   
                                        <!-- project title-->
                                        <h4 class="mt-0 mb-1 text-center">
                                            <a href="#" class="text-title">{{ product.title}}</a>
                                        </h4>
                                        <p class="text-center" style="font-size:0.75rem;margin-bottom:5px;margin-top:0px;">{{ product.packaging}}: {{product.size}}</p>
                                        <p class="text-center">{{this.$parent.formatCurrency(product.price)}}</p>
                                        <div v-if="$store.state.startOrder" class="row d-flex" style="margin-top:-10px;">
                                            <div class="col-4">
                                                <button type="button" @click="remOne" class="btn btn-light" ><i class="uil uil-minus"></i></button>
                                            </div>
                                            <div class="col-4" style="display:flex;justify-content:center">
                                                <input v-bind:class="{
                                                    'btn-outline-success' : qty >=1 && qty <= 10,
                                                    'btn-outline-danger' : qty >=10,
                                                    }" 
                                                    style="margin: 0 auto;width: 65px;" v-model="qty" type="number" min="1" value="1" class="form-control text-center" placeholder="Qty" >
                                            </div>
                                            <div class="col-4">
                                                <button type="button" @click="addOne" class="btn btn-light" ><i class="uil uil-plus"></i></button>
                                            </div>
                                            
                                        </div>
                                        <!-- project detail-->
                                        
                                        

                                        <!-- project progress-->
                                        
                                    </div> <!-- end card-body-->
                                </div> <!-- end card-->
                            </div>
</template>
<script>
export default {
    name: 'catalog-item',
    props: {
        product: {}
    },
    data() {
        return {
            qty: 0,
        }
    },
    created() {
        
        let index = this.$store.state.cart.findIndex(item => item.id == this.product.id);
        if(index !== -1) {
            this.qty = this.$store.state.cart[index].quantity;
        }
    },
    methods: {
        addOne() {
            this.qty += 1;
            console.log(this.product);
            this.$store.commit('addToCart', this.product);
        },
        remOne() {
            if(this.qty > 1) {
                this.qty -= 1;
                this.product.quantity = this.qty;
                console.log('still larger than one');
                this.$store.commit('remFromCart', this.product);
            } else if(this.qty == 1) {
                console.log('zeroed after this or delete');
            }
        }
    }
}
</script>
