<template>
    <div  class="content">
                    

                    <!-- Start Content-->
                    <!-- Start Content-->
                    <div v-if="loading == false" class="container-fluid" style="margin-top:-56px;">
                        
                        <!-- start page title -->
                          
                        <!-- end page title --> 

                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row mb-2">
                                            <div class="col-xl-8">
                                                <form class="row gy-2 gx-2 align-items-center justify-content-xl-start justify-content-between">
                                                    <div class="col-auto">
                                                        <div class="text-xl-end mt-xl-0 mt-2">
                                                            <button v-if="!$store.state.startOrder" @click="$router.push({name: 'order-start'})" type="button" class="btn btn-outline-success mb-2 me-2">
                                                                <i class="uil uil-plus-circle"></i> Create Order
                                                            </button>
                                                            <button v-if="$store.state.startOrder" @click="cancelOrder" class="btn  btn-rounded mb-3" style="margin-left:-10px;">
                                                                <i class="uil uil-multiply"></i>
                                                            </button>
                                                            <button v-if="$store.state.startOrder" class="btn btn-outline-success btn-rounded mb-3" style="margin-left:10px;">
                                                                <i class="uil uil-plus"></i> Review Order
                                                            </button>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <div class="d-flex align-items-center">
                                                            <label for="status-select" class="me-2">Status</label>
                                                            <select class="form-select" id="status-select">
                                                                <option selected="">Choose...</option>
                                                                <option value="1">Paid</option>
                                                                <option value="2">Awaiting Authorization</option>
                                                                <option value="3">Payment failed</option>
                                                                <option value="4">Cash On Delivery</option>
                                                                <option value="5">Fulfilled</option>
                                                                <option value="6">Unfulfilled</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form>                            
                                            </div>
                                           
                                        </div>
                
                                        <div class="table-responsive">
                                            <table class="table table-centered mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th style="width: 20px;">
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck1">
                                                                <label class="form-check-label" for="customCheck1">&nbsp;</label>
                                                            </div>
                                                        </th>
                                                        <th>Order ID</th>
                                                        <th>Date</th>
                                                        <th>Client</th>
                                                        <th style="width: 185px;">Address</th>
                                                        <th>Order Status</th>
                                                        <th>Total</th>
                                                        <th style="width: 125px;">AR</th>
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    <tr v-for="i in ordersList" :key="i">
                                                        <td>
                                                            <div class="form-check">
                                                                <input type="checkbox" class="form-check-input" id="customCheck2">
                                                                <label class="form-check-label" for="customCheck2">&nbsp;</label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <router-link :to="{name:'order-view', params: {id: i.id}}" class="text-body fw-bold">{{i.id}}
                                                            </router-link> 
                                                        </td>
                                                        <td>
                                                            {{ i.created_at | moment("MM/DD  h:mma") }}
                                                        </td>
                                                        <td>
                                                            {{i.client.name}}
                                                        </td>
                                                        <td>
                                                            {{i.client.address}}, {{i.client.city}} {{i.client.zip}}
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-warning-lighten" style="background-color:rgba(0,0,0,0.2);color:rgba(0,0,0,0.8)">Start</span></h5>
                                                        </td>
                                                        <td>
                                                            {{formatCurrency(i.total)}}
                                                        </td>
                                                        <td>
                                                            <h5><span class="badge badge-info-lighten">Unpaid</span></h5>
                                                        </td>
                                                    </tr> 
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> <!-- end card-body-->
                                </div> <!-- end card-->
                            </div> <!-- end col -->
                        </div>
                        <!-- end row --> 
                        
                    </div>
                    <div v-else class="container-fluid" style="display:flex;align-items:center;justify-content:center;">
                        <span style="top: 42%;position:absolute;">
                            <div class="spinner-border" role="status">
                            
                            </div>
                        </span>
                    </div>

    </div> <!-- content -->
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            loading: false,
            ordersList: '',
        }
    },
    created() {
        this.fetchOrders();
    },
    methods: {
        fetchOrders() {
            this.loading = true;
            axios.get('/api/fetchuserorders?query='+localStorage.getItem('access_token')).then(resp => {
                this.ordersList = resp.data.success.data;
                console.log(resp.data);
                this.loading = false;
            });
        },
        cancelOrder() {
            this.$store.commit('cancelorder');
        },
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
    }
}
</script>