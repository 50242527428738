<template>
<div class="content">
                    <!-- Topbar Start -->
                  
                    <!-- end Topbar -->

                    <!-- Start Content-->
                    <div class="container-fluid" style="margin-top:-56px;">

                        <!-- start page title -->
                    
                        <!-- end page title -->

                        <div class="row" style="margin-top:12%;">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">

                                       

                                        <!-- Steps Information -->
                                        

                                            <!-- Billing Content-->
                                            <div class="tab-pane show active" id="billing-information">
                                                <div class="row justify-content-center" style="">
                                                    <div class="col-lg-8">
                                                        <h4 class="mt-2">Order Creation</h4>

                                                        <p class="text-muted mb-4">Select a client to start generating a sales order.</p>

                                                       
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <ul class="nav nav-pills bg-nav-pills nav-justified mb-3">
                                                                        <li class="nav-item">
                                                                            <a href="#billing-information" data-bs-toggle="tab" aria-expanded="false" class="nav-link rounded-0 active">
                                                                                
                                                                                <i class="uil uil-users-alt font-18"></i>
                                                                                <span class="d-none d-lg-block">Select</span>
                                                                            </a>
                                                                        </li>
                                                                        <li class="nav-item">
                                                                            <a href="#shipping-information" data-bs-toggle="tab" aria-expanded="true" class="nav-link rounded-0">
                                                                           
                                                                                <i class="uil uil-search font-18"></i>
                                                                                <span class="d-none d-lg-block">Search</span>
                                                                            </a>
                                                                        </li>
                                                                        
                                                                    </ul>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="mb-3">
                                                                        <label for="example-multiselect" class="form-label">Select Client</label>
                                                                        <div class="form-floating">
                                                                            <select class="form-select" v-model="selectedClient" id="floatingSelect" aria-label="Floating label select example">
                                                                                <option selected>Open this select menu</option>
                                                                                <option v-for="i in clientList"  :value="i.slug" :key="i.id" >{{i.name}}</option>
                                                                                
                                                                            </select>
                                                                            <label for="floatingSelect">Client List</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                               
                                                               
                                                             
                                                                
                                                              
                                                            </div> <!-- end row -->
                                                            
                                                            
                                                            

                                                            

                                                            <div class="row mt-4 pt-4">
                                                                <div class="col-sm-6">
                                                                    <button @click="$router.go(-1)" class="btn text-muted d-none d-sm-inline-block btn-link fw-semibold">
                                                                        <i class="uil uil-arrow-left"></i> Back to Catalog </button>
                                                                </div> <!-- end col -->
                                                                <div class="col-sm-6">
                                                                    <div class="text-sm-end">
                                                                        <button class="btn btn-success" @click="startOrder">
                                                                            <i class="uil uil-user-plus"></i> Proceed to Create 
                                                                        </button>
                                                                    </div>
                                                                </div> <!-- end col -->
                                                            </div> <!-- end row -->
                                                       
                                                    </div>
                                                              
                                                </div> <!-- end row-->
                                            </div>
                                            <!-- End Billing Information Content-->

                                            <!-- Shipping Content-->
                                       
                                            <!-- End Payment Information Content-->

                                    

                                    </div> <!-- end card-body-->
                                </div> <!-- end card-->
                            </div> <!-- end col -->
                        </div>
                        <!-- end row-->

                    </div> <!-- container -->

</div> <!-- content -->
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            clientList: '',
            selectedClient: {},
        }
    },
    created() {
        this.fetchClients();
    },
    computed: {
        fetchC() {
            axios.get('/api/fetchuserclient2').then(resp => {
                console.log(resp);
                return resp.data.list.data;
                
            });
        }
    },
    methods: {
        startOrder() {
            console.log('starting order: ' + this.selectedClient);
            this.$store.commit('startorder', this.selectedClient);
            console.log('from state: ' + this.$store.state.startOrder);
            this.$router.push({ name: 'catalog' });
        },
        fetchClients() {
            
            axios.get('/api/clientstartorder?query='+localStorage.getItem('access_token')).then(resp => {
                this.clientList = resp.data.success
                console.log(resp.data);
            });
        }
    }
}
</script>