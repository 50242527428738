<template>
<div class="content">
                    <!-- Topbar Start -->
                  
                    <!-- end Topbar -->

                    <!-- Start Content-->
                    <div class="container-fluid" style="margin-top:-56px;">

                        <!-- start page title -->
                    
                        <!-- end page title -->

                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">

                                       

                                        <!-- Steps Information -->
                                        

                                            <!-- Billing Content-->
                                            <div class="tab-pane show active" id="billing-information">
                                                <div class="row justify-content-center">
                                                    <div class="col-lg-8">
                                                        <h4 class="mt-2">Client Creation</h4>

                                                        <p class="text-muted mb-4">Fill the form below in order to
                                                            onboard a new client.</p>

                                                        
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="mb-3">
                                                                        <label for="example-textarea" class="form-label">Company Name:</label>
                                                                        <input class="form-control" v-model="formInput.name" type="text" placeholder="Enter name" id="billing-address">
                                                                    </div>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="mb-3">
                                                                        <label for="billing-address" class="form-label">Address</label>
                                                                        <input class="form-control" v-model="formInput.address" type="text" placeholder="Enter full address" id="billing-address">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="mb-3">
                                                                        <label for="billing-town-city" class="form-label">Town / City</label>
                                                                        <input class="form-control" v-model="formInput.city" type="text" placeholder="Enter city name" id="billing-town-city" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="mb-3">
                                                                        <label for="billing-state" class="form-label">State</label>
                                                                        <input class="form-control" v-model="formInput.state" type="text" placeholder="Enter state" id="billing-state" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="mb-3">
                                                                        <label for="billing-zip-postal" class="form-label">Zip / Postal Code</label>
                                                                        <input class="form-control" v-model="formInput.zip" type="text" placeholder="Enter zip code" id="billing-zip-postal" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="mb-3">
                                                                        <label for="billing-first-name" class="form-label">Manager</label>
                                                                        <input class="form-control" v-model="formInput.manager" type="text" placeholder="Enter your first name" id="billing-first-name" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="mb-3">
                                                                        <label for="billing-phone" class="form-label">Phone </label>
                                                                        <input class="form-control" v-model="formInput.phone" type="text" placeholder="(xx) xxx xxxx xxx" id="billing-phone" />
                                                                    </div>
                                                                </div>
                                                            </div> <!-- end row -->
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="mb-3">
                                                                        <label for="billing-email-address" class="form-label">Email Address </label>
                                                                        <input class="form-control" v-model="formInput.email" type="email" placeholder="Enter your email" id="billing-email-address" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="mb-3">
                                                                        <label for="billing-phone" class="form-label">Phone alt </label>
                                                                        <input class="form-control" v-model="formInput.phone2" type="text" placeholder="(xx) xxx xxxx xxx" id="billing-phone" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="mb-3">
                                                                        <label for="billing-phone" class="form-label">Client Type </label>
                                                                        <select class="form-select">
                                                                            <option selected>Select From Menu</option>
                                                                            <option value="1">Commercial</option>
                                                                            <option value="2">Market</option>
                                                                            <option value="3">Supermarket</option>
                                                                            <option value="3">Farmersmarket</option>
                                                                        </select> 
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="mb-3">
                                                                        <label for="billing-email-address" class="form-label">Prospect Type </label>
                                                                        <select class="form-select">
                                                                            <option selected>Select From Menu</option>
                                                                            <option value="1">Hard Prospect</option>
                                                                            <option value="2">Soft Prospect</option>
                                                                        </select> 
                                                                    </div>
                                                                </div>
                                                                
                                                            </div> <!-- end row -->
                                                            
                                                            
                                                            

                                                            <div class="row">
                                                                <div class="col-12">
                                                                    

                                                                    <div class="mb-3 mt-3">
                                                                        <label for="example-textarea" class="form-label">Client Notes:</label>
                                                                        <textarea class="form-control" v-model="formInput.notes" id="example-textarea" rows="3" placeholder="Write some note.."></textarea>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div> <!-- end row -->

                                                            <div class="row mt-4">
                                                                <div class="col-sm-6">
                                                                    <button @click="$router.go(-1);" class="btn text-muted d-none d-sm-inline-block btn-link fw-semibold">
                                                                        <i class="uil uil-arrow-left"></i> Back to Client List 
                                                                    </button>
                                                                </div> <!-- end col -->
                                                                <div class="col-sm-6">
                                                                    <div class="text-sm-end">
                                                                        <button @click="clientCreate" class="btn btn-outline-info">
                                                                            <i class="uil uil-user-plus"></i> Proceed to Create 
                                                                        </button>
                                                                    </div>
                                                                </div> <!-- end col -->
                                                            </div> <!-- end row -->
                                                        
                                                    </div>
                                                              
                                                </div> <!-- end row-->
                                            </div>
                                            <!-- End Billing Information Content-->

                                            <!-- Shipping Content-->
                                       
                                            <!-- End Payment Information Content-->

                                    

                                    </div> <!-- end card-body-->
                                </div> <!-- end card-->
                            </div> <!-- end col -->
                        </div>
                        <!-- end row-->

                    </div> <!-- container -->

                </div> <!-- content -->
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            formInput: {
                name: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                phone2: '',
                manager: '',
                email: '',
                notes: '',
            },
        }
    },
    methods: {
        clientCreate() {
            console.log('creating client');
            console.log(this.formInput);
            if(this.formInput.name !== '' && this.formInput.address !== '' && this.formInput.city !== '' && this.formInput.state !== '' && this.formInput.zip !== '' && this.formInput.phone != '') {
                axios.post('/api/createclient', {
                    form: this.formInput,
                    rep: localStorage.getItem('access_token'),
                    }).then(resp => {
                    console.log(resp);
                    
                }).then(resp2 => {
                    //this.$router.push({name: 'client-list'});
                    window.scrollTo(0,0);
                    this.$toasted.success('Client Added');
                });
            } else {
                this.$toasted.error('Missing');
            }
            

            
        }
    }
}
</script>