<template>
    <div class="content">
                    <!-- Topbar Start -->
                    
                    <!-- end Topbar -->

                    <!-- Start Content-->
                    <div class="container-fluid" style="margin-top:-56px;">
                        
                        <!-- start page title -->
                        <div class="row">
                            <div class="col-12">
                                <div class="page-title-box">
                                    <div class="page-title-right">
                                        <ol class="breadcrumb m-0">
                                          
                                        </ol>
                                    </div>
                                    <div class="page-title">
                                        <router-link :to="{name: 'edit-routes'}" data-bs-toggle="modal" class="btn btn-success btn-sm ms-3">
                                            Add Route
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>     
                        <!-- end page title --> 

                        <div class="row">
                            <div class="col-12">
                                <div class="board routesListed">
                                    <div v-if="this.loading1 == true" class="tasks">
                                        <div class="w-100" style="display:flex;justify-content:center;margin-top:12px;">
                                            <div class="spinner-border" role="status" style="margin: 0 auto;">
                                                                    
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div v-else class="tasks">
                                        <h5 class="mt-0 task-header">{{day1}} ({{routeList1.length}})</h5>
                                        
                                        <div >

                                            <!-- Task Item -->
                                            <div v-for="i in routeList1" :key="i" class="card mb-0">
                                                <div class="card-body p-2">
                                                    
                                                   

                                                    <h5 class="mt-1 mb-1">
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#task-detail-modal" class="text-body">{{i.client.name}}</a>
                                                    </h5>

                                                    <p class="mb-0">
                                                        
                                                        <p class="d-inline-block">
                                                            
                                                            {{i.client.address}} <br>{{i.client.city}}, {{i.client.state}} {{i.client.zip}}
                                                        </p>
                                                    </p>

                                                    

                                                   
                                                </div> <!-- end card-body -->
                                            </div>
                                            <!-- Task Item End -->
                                            
                                        </div> <!-- end company-list-1-->
                                    </div>

                                    <div v-if="this.loading2 == true" class="tasks">
                                        <div class="w-100" style="display:flex;justify-content:center;margin-top:12px;">
                                            <div class="spinner-border" role="status" style="margin: 0 auto;">
                                                                    
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div v-else class="tasks">
                                        <h5 class="mt-0 task-header">{{day2}} ({{routeList2.length}})</h5>
                                        
                                        <div >

                                            <!-- Task Item -->
                                            <div v-for="i in routeList2" :key="i" class="card mb-0">
                                                <div class="card-body p-2">
                                                    
                                                   

                                                    <h5 class="mt-1 mb-1">
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#task-detail-modal" class="text-body">{{i.client.name}}</a>
                                                    </h5>

                                                    <p class="mb-0">
                                                        
                                                        <p class="d-inline-block">
                                                            
                                                            {{i.client.address}} <br>{{i.client.city}}, {{i.client.state}} {{i.client.zip}}
                                                        </p>
                                                    </p>

                                                    

                                                   
                                                </div> <!-- end card-body -->
                                            </div>
                                            <!-- Task Item End -->
                                            
                                        </div> <!-- end company-list-1-->
                                    </div>

                                    <div v-if="this.loading3 == true" class="tasks">
                                        <div class="w-100" style="display:flex;justify-content:center;margin-top:12px;">
                                            <div class="spinner-border" role="status" style="margin: 0 auto;">
                                                                    
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div v-else class="tasks">
                                        <h5 class="mt-0 task-header">{{day3}} ({{routeList3.length}})</h5>
                                        
                                        <div >

                                            <!-- Task Item -->
                                            <div v-for="i in routeList3" :key="i" class="card mb-0">
                                                <div class="card-body p-2">
                                                    
                                                   

                                                    <h5 class="mt-1 mb-1">
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#task-detail-modal" class="text-body">{{i.client.name}}</a>
                                                    </h5>

                                                    <p class="mb-0">
                                                        
                                                        <p class="d-inline-block">
                                                            
                                                            {{i.client.address}} <br>{{i.client.city}}, {{i.client.state}} {{i.client.zip}}
                                                        </p>
                                                    </p>

                                                    

                                                   
                                                </div> <!-- end card-body -->
                                            </div>
                                            <!-- Task Item End -->
                                            
                                        </div> <!-- end company-list-1-->
                                    </div>

                                    <div v-if="this.loading4 == true" class="tasks">
                                        <div class="w-100" style="display:flex;justify-content:center;margin-top:12px;">
                                            <div class="spinner-border" role="status" style="margin: 0 auto;">
                                                                    
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div v-else class="tasks">
                                        <h5 class="mt-0 task-header">{{day4}} ({{routeList4.length}})</h5>
                                        
                                        <div >

                                            <!-- Task Item -->
                                            <div v-for="i in routeList1" :key="i" class="card mb-0">
                                                <div class="card-body p-2">
                                                    
                                                   

                                                    <h5 class="mt-1 mb-1">
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#task-detail-modal" class="text-body">{{i.client.name}}</a>
                                                    </h5>

                                                    <p class="mb-0">
                                                        
                                                        <p class="d-inline-block">
                                                            
                                                            {{i.client.address}} <br>{{i.client.city}}, {{i.client.state}} {{i.client.zip}}
                                                        </p>
                                                    </p>

                                                    

                                                   
                                                </div> <!-- end card-body -->
                                            </div>
                                            <!-- Task Item End -->
                                            
                                        </div> <!-- end company-list-1-->
                                    </div>

                                    <div v-if="this.loading5 == true" class="tasks">
                                        <div class="w-100" style="display:flex;justify-content:center;margin-top:12px;">
                                            <div class="spinner-border" role="status" style="margin: 0 auto;">
                                                                    
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div v-else class="tasks">
                                        <h5 class="mt-0 task-header">{{day5}} ({{routeList5.length}})</h5>
                                        
                                        <div >

                                            <!-- Task Item -->
                                            <div v-for="i in routeList5" :key="i" class="card mb-0">
                                                <div class="card-body p-2">
                                                    
                                                   

                                                    <h5 class="mt-1 mb-1">
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#task-detail-modal" class="text-body">{{i.client.name}}</a>
                                                    </h5>

                                                    <p class="mb-0">
                                                        
                                                        <p class="d-inline-block">
                                                            
                                                            {{i.client.address}} <br>{{i.client.city}}, {{i.client.state}} {{i.client.zip}}
                                                        </p>
                                                    </p>

                                                    

                                                   
                                                </div> <!-- end card-body -->
                                            </div>
                                            <!-- Task Item End -->
                                            
                                        </div> <!-- end company-list-1-->
                                    </div>

                                </div> <!-- end .board-->
                            </div> <!-- end col -->
                        </div>
                        <!-- end row-->
                        
                    </div>

                </div> <!-- content -->
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            loading1: false,
            loading2: false,
            loading3: false,
            loading4: false,
            loading5: false,
            routeList1: '',
            day1: '',
            routeList2: '',
            day2: '',
            routeList3: '',
            day3: '',
            routeList4: '',
            day4: '',
            routeList5: '',

        }
    },
    created() {
        this.fetchRoutes();
    },
    methods: {
        fetchRoutes() {
            this.loading = true;
            axios.get('/api/map/fetchroutelistall?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data.success);
                this.routeList1 = resp.data.list1;
                this.routeList2 = resp.data.list2;
                this.routeList3 = resp.data.list3;
                this.routeList4 = resp.data.list4;
                this.routeList5 = resp.data.list5;

                this.day1 = resp.data.day1;
                this.day2 = resp.data.day2;
                this.day3 = resp.data.day3;
                this.day4 = resp.data.day4;
                this.day5 = resp.data.day5;
              
            });
        },
        fetchRoutes1() {
            this.loading1 = true;
            axios.get('/api/map/fetchroutelist1?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data);
                this.day1 = resp.data.day;
                this.routeList1 = resp.data.success;
                this.loading1 = false;
              
            });
        },
        fetchRoutes2() {
            this.loading2 = true;
            axios.get('/api/map/fetchroutelist2?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data);
                this.day2 = resp.data.day;
                this.routeList2 = resp.data.success;
                this.loading2 = false;
            });
        },
        fetchRoutes3() {
            this.loading3 = true;
            axios.get('/api/map/fetchroutelist3?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data);
                this.day3 = resp.data.day;
                this.routeList3 = resp.data.success;
                this.loading3 = false;
            });
        },
        fetchRoutes4() {
            this.loading4 = true;
            axios.get('/api/map/fetchroutelist4?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data);
                this.day4 = resp.data.day;
                this.routeList4 = resp.data.success;
                this.loading4 = false;
            });
        },
        fetchRoutes5() {
            this.loading5 = true;
            axios.get('/api/map/fetchroutelist5?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data);
                this.day5 = resp.data.day;
                this.routeList5 = resp.data.success;
                this.loading5 = false;
            });
        },
    },
}
</script>