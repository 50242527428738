<template>
<div class="content">
                    

                    <div v-if="loading == false" class="container-fluid" style="margin-top:-56px;">
                        
                        <!-- start page title -->
                       
                        <!-- end page title --> 

                        <div class="row mt-3 mb-2">
                            <div class="col-sm-4" style="">
                                <router-link v-if="!$store.state.startOrder" :to="{name: 'order-start'}" class="btn btn-outline-success btn-rounded mb-3">
                                    <i class="uil uil-plus"></i> Create Order
                                </router-link>
                                <button v-if="$store.state.startOrder" @click="cancelOrder" class="btn  btn-rounded mb-3" style="margin-left:-10px;">
                                    <i class="uil uil-multiply"></i>
                                </button>
                                <router-link v-if="$store.state.startOrder" :to="{name: 'order-review'}" class="btn btn-outline-success btn-rounded mb-3" style="margin-left:10px;">
                                    <i class="uil uil-plus"></i> Review Order
                                </router-link>
                                
                               <div class="page-title-box">
                                    <div class="page-title-left mb-1" style="margin-top:-8px;padding-top:0px;">
                                        <div class="app-search">
                                            
                                                <div class="input-group">
                                                    <input type="text" :keyup="searchFunction()" v-model="searchQuery" class="form-control" placeholder="Search...">
                                                    <span class="uil uil-search search-icon" style="font-size:0.75rem;"></span>
                                                    
                                                </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="text-sm-end" style="">
                                    <div class="btn-group mb-2">
                                        <button @click="searchValue = ''" type="button" class="btn" :class="{'btn-outline-success' : searchValue == '', 'btn-light' : searchValue !== '' }" style="">All</button>
                                    </div>
                                    <div class="btn-group mb-2 ms-1">
                                        <button @click="searchValue = 'Granola', searchQuery = ''" type="button" class="btn" :class="{'btn-outline-success' : searchValue == 'Granola', 'btn-light' : searchValue !== 'Granola' }" style="margin: 0 1px;">Granola</button>
                                        <button  @click="searchValue = 'Grocery', searchQuery = ''" type="button" class="btn btn-light" :class="{'btn-outline-success' : searchValue == 'Grocery', 'btn-light' : searchValue !== 'Grocery' }" style="margin: 0 1px;">Grocery</button>
                                        <button  @click="searchValue = 'Nuts', searchQuery = ''" type="button" class="btn btn-light" :class="{'btn-outline-success' : searchValue == 'Nuts', 'btn-light' : searchValue !== 'Nuts' }" style="margin: 0 1px;">Nuts</button>
                                        <button  @click="searchValue = 'Fruit', searchQuery = ''" type="button" class="btn btn-light" :class="{'btn-outline-success' : searchValue == 'Fruit', 'btn-light' : searchValue !== 'Fruit' }" style="margin: 0 1px;">Fruits</button>
                                    </div>
                                  
                                    
                                </div>
                                <div class="text-sm-end">
                                    <div class="btn-group mb-3">
                                        <button @click="searchSubValue = ''" type="button" class="btn" :class="{'btn-outline-success' : searchSubValue == '', 'btn-light' : searchSubValue !== '' }" style="">All</button>
                                    </div>
                                    <div class="btn-group mb-3 ms-1">
                                        <button type="button" @click="searchSubValue = 'Pouch'" class="btn" :class="{'btn-outline-success' : searchSubValue == 'Pouch', 'btn-light' : searchSubValue !== 'Pouch' }" style="margin: 0 1px;">Pouch</button>
                                        <button type="button" @click="searchSubValue = 'Bulk'" class="btn" :class="{'btn-outline-success' : searchSubValue == 'Bulk', 'btn-light' : searchSubValue !== 'Bulk' }" style="margin: 0 1px;">Bulk</button>
                                        <button type="button" @click="searchSubValue = 'Pallet'" class="btn" :class="{'btn-outline-success' : searchSubValue == 'Pallet', 'btn-light' : searchSubValue !== 'Pallet' }" style="margin: 0 1px;">Pallet</button>
                                    </div>
                                  
                                    
                                </div>
                                 
                            </div><!-- end col-->
                        </div> 
                        <!-- end row-->

                        
                        <!-- end row-->

                        <div class="row">
                            <catalog-item v-for="i in filterItems" :product="i" :key="i.id"></catalog-item>
                             <!-- end col -->
`
                           
                            

                        </div>
                        <!-- end row-->
                        
                    </div> <!-- container -->
                    <div v-else class="container-fluid" style="display:flex;align-items:center;justify-content:center;">
                        <span style="top: 42%;position:absolute;">
                            <div class="spinner-border" role="status">
                            
                            </div>
                        </span>
                    </div>
                </div> <!-- content -->
</template>
<script>
import axios from 'axios';
import catalogItem from "./comps/CatalogItem.vue"

export default{
    components: {
        catalogItem,
    },
    data() {
        return {
            loading: false,
            searchValue: '',
            searchSubValue: '',
            searchQuery: '',
            items: '',
        }
    },
    created() {
        this.fetchCatalog();
    },
    computed: {
        filterItems() {
            let tempValues = this.items;
            if (this.searchQuery.length >= 3) {
                tempValues = tempValues.filter((item) => {
                return item.title
                    .toUpperCase()
                    .includes(this.searchQuery.toUpperCase())
                });
                if(this.searchSubValue != '') {
                    tempValues = tempValues.filter((item) => {
                    return item.packaging
                        .toUpperCase()
                        .includes(this.searchSubValue.toUpperCase())
                    });
                }
            }
            if (this.searchValue != '') {
                tempValues = tempValues.filter((item) => {
                return item.category
                    .toUpperCase()
                    .includes(this.searchValue.toUpperCase())
                });
                if(this.searchSubValue != '') {
                    tempValues = tempValues.filter((item) => {
                    return item.packaging
                        .toUpperCase()
                        .includes(this.searchSubValue.toUpperCase())
                    });
                }
            }
            if (this.searchValue == '' && this.searchSubValue != '') {
                tempValues = tempValues.filter((item) => {
                return item.packaging
                    .toUpperCase()
                    .includes(this.searchSubValue.toUpperCase())
                });
            }
            return tempValues;
        },
    },
    methods: {
        fetchCatalog() {
            this.loading = true;
            console.log('fetching');
            axios.get('/api/fetchcatalog').then(resp => {
                this.items = resp.data.catalog.data; 
                console.log(resp.data.catalog.data);
                this.loading = false;
            });
        },
        cancelOrder() {
            this.$store.commit('cancelorder');
        },
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        searchFunction() {
            if(this.searchQuery.length > 2) { this.searchValue = ''}
        }
    }
}
</script>
