<template>
    <div style="position:absolute;top:0px;height:100%;width:100%;">
         <div class="card" style="position:absolute;height:74%;width:64%;margin-top:4px;">
             <GmapMap
      :center='center'
      :zoom='14'
      style='width:100%;  height: 100%'
    />
         </div>
         <div class="card routesListed" style="position:absolute;left:64.4%;width:28%;height:74%;margin-top:4px;overflow-y:scroll;overflow-x:hidden;">
                                    <div class="card-body">
                                        <div class="dropdown float-end">
                                            <a href="#" class="dropdown-toggle arrow-none card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="mdi mdi-dots-vertical"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end">
                                                <!-- item-->
                                                <a href="javascript:void(0);" class="dropdown-item">Settings</a>
                                                <!-- item-->
                                                <a href="javascript:void(0);" class="dropdown-item">Action</a>
                                            </div>
                                        </div>
                                        <h4 class="header-title mb-3">All Routes</h4>

                                        <div v-for="(i, index) in routeList" :key="i.id" class="inbox-widget">
                                            <div class="inbox-item">
                                                <div class="row">
                                                    <div class="col-23">
                                                       
                                                        <div class="d-flex mt-1">
                                                            <div v-if="loadRoute == i.id" class="w-100" style="display:flex;justify-content:between;">
                                                                    <div class="spinner-border" role="status" style="margin: 0 auto;">
                                                                    
                                                                    </div>
                                                            </div>
                                                            <div v-else class="w-100">
                                                                <h5 class="mt-0 mb-0">
                                                                    <span class="float-end text-muted font-12"><i @click="routeClick(i, index)" class="uil uil-plus-circle" style="font-size:1rem;"></i></span>
                                                                    <span  >{{i.client.name}}</span>
                                                                </h5>
                                                                <p class="mt-1 mb-0 text-muted">
                                                                    {{i.sugg_return}}
                                                                </p>
                                                                <p class="mt-1 mb-0 text-muted">
                                                                    {{i.note}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                
                                                
                                                
                                            </div>
                            
                                            
                                        </div> <!-- end inbox-widget -->
                                    </div> <!-- end card-body-->
         </div>
         <div class="card" style="position:absolute;top:75%;height:25%;width:92.4%;left:0px;">
             <div class="row" style="background-color:white;">
                            <div class="col-12">
                                <div class="board">
                                    <!-- Day + 1 ---------------------->
                                    <div class="tasks">
                                        <h5 @click="dayRefSet('one')" :class="{'selectedDayNot': this.dayRef !== 'one','selectedDay': this.dayRef == 'one'}"
                                            class="mt-0 "  style="font-size:0.85rem;padding-top:20px;">{{day1}}</h5>

                                        <div id="task-list-one" class="">
                                            <!-- Task Item -->
                                            <div v-for="(i, index) in routeList1" :key="i.id" class="card mb-0">
                                                <div class="card-body p-2">                   
                                                        <div class="form-check">
                                                            <div v-if="unloadRoute == i.id" class="w-100" style="display:flex;justify-content:between;">
                                                                    <div class="spinner-border" role="status" style="margin: 0 auto;">
                                                                    
                                                                    </div>
                                                            </div>
                                                            <div v-else class="w-100">
                                                                <h5 class="mt-0 mb-0" style="">
                                                                    <span class="float-end text-muted" style=""><i @click="routeRemClick1(i.id, index)" class="uil uil-multiply" style="font-size:0.78rem"></i></span>
                                                                    <span  >{{i.client.name}}</span>
                                                                </h5>
                                                            </div>
                                                        </div> <!-- end checkbox -->                                                 
                                                </div> <!-- end card-body -->
                                            </div>
                                            <!-- Task Item End -->
                                            
                                        </div> <!-- end company-list-1-->
                                    </div>
                                    <!-- Day + 2 -->
                                    <div class="tasks">
                                        <h5 @click="dayRefSet('two')" :class="{'selectedDayNot': this.dayRef !== 'two','selectedDay': this.dayRef == 'two'}" class="mt-0" style="font-size:0.85rem;padding-top:20px;">{{day2}}</h5>

                                        <div id="task-list-one" class="">
                                            <!-- Task Item -->
                                            <div v-for="(i, index) in routeList2" :key="i" class="card mb-0">
                                                <div class="card-body p-2">                   
                                                        <div class="form-check">
                                                          
                                                            <div class="w-100">
                                                                <h5 class="mt-0 mb-0" style="">
                                                                    <span class="float-end text-muted" style=""><i @click="routeRemClick2(i.id, index)" class="uil uil-multiply" style="font-size:0.78rem"></i></span>
                                                                    <span  >{{i.client.name}}</span>
                                                                </h5>
                                                            </div>
                                                        </div> <!-- end checkbox -->                                                 
                                                </div> <!-- end card-body -->
                                            </div>
                                            <!-- Task Item End -->
                                            
                                        </div> <!-- end company-list-1-->
                                    </div>
                                    <!-- Day + 3 -->
                                    <div class="tasks">
                                        <h5 @click="dayRefSet('three')" :class="{'selectedDayNot': this.dayRef !== 'three','selectedDay': this.dayRef == 'three'}" class="mt-0" style="font-size:0.85rem;padding-top:20px;">{{day3}}</h5>

                                        <div id="task-list-one" class="">
                                            <!-- Task Item -->
                                            <div v-for="(i, index) in routeList3" :key="i" class="card mb-0">
                                                <div class="card-body p-2">                   
                                                        <div class="form-check">
                                                          
                                                            <div class="w-100">
                                                                <h5 class="mt-0 mb-0" style="">
                                                                    <span class="float-end text-muted" style=""><i @click="routeRemClick3(i.id, index)" class="uil uil-multiply" style="font-size:0.78rem"></i></span>
                                                                    <span  >{{i.client.name}}</span>
                                                                </h5>
                                                            </div>
                                                        </div> <!-- end checkbox -->                                                 
                                                </div> <!-- end card-body -->
                                            </div>
                                            <!-- Task Item End -->
                                            
                                        </div> <!-- end company-list-1-->
                                    </div>
                                    <!-- Day + 4 -->
                                    <div class="tasks">
                                        <h5 @click="dayRefSet('four')" :class="{'selectedDayNot': this.dayRef !== 'four','selectedDay': this.dayRef == 'four'}" class="mt-0" style="font-size:0.85rem;padding-top:20px;">{{day4}}</h5>

                                        <div id="task-list-one" class="">
                                            <!-- Task Item -->
                                            <div v-for="(i, index) in routeList4" :key="i" class="card mb-0">
                                                <div class="card-body p-2">                   
                                                        <div class="form-check">
                                                          
                                                            <div class="w-100">
                                                                <h5 class="mt-0 mb-0" style="">
                                                                    <span class="float-end text-muted" style=""><i @click="routeRemClick4(i.id, index)" class="uil uil-multiply" style="font-size:0.78rem"></i></span>
                                                                    <span>{{i.client.name}}</span>
                                                                </h5>
                                                            </div>
                                                        </div> <!-- end checkbox -->                                                 
                                                </div> <!-- end card-body -->
                                            </div>
                                            <!-- Task Item End -->
                                            
                                        </div> <!-- end company-list-1-->
                                    </div>
                                    <!-- Day + 5 -->
                                    <div class="tasks">
                                        <h5 @click="dayRefSet('five')" :class="{'selectedDayNot': this.dayRef !== 'five','selectedDay': this.dayRef == 'five'}" class="mt-0" style="font-size:0.85rem;padding-top:20px;">{{day5}}</h5>

                                        <div id="task-list-one" class="">
                                            <!-- Task Item -->
                                            <div v-for="(i, index) in routeList5" :key="i" class="card mb-0">
                                                <div class="card-body p-2">                   
                                                        <div class="form-check">
                                                          
                                                            <div class="w-100">
                                                                <h5 class="mt-0 mb-0" style="">
                                                                    <span class="float-end text-muted" style=""><i @click="routeRemClick5(i.id, index)" class="uil uil-multiply" style="font-size:0.78rem"></i></span>
                                                                    <span  >{{i.client.name}}</span>
                                                                </h5>
                                                            </div>
                                                        </div> <!-- end checkbox -->                                                 
                                                </div> <!-- end card-body -->
                                            </div>
                                            <!-- Task Item End -->
                                            
                                        </div> <!-- end company-list-1-->
                                    </div>

                                </div> <!-- end .board-->
                            </div> <!-- end col -->
                        </div>
         </div>
    </div> <!-- content -->
</template>
<script>
import axios from 'axios';

export default {
    components: {
      
    },
    data() {
        return {
            center: { lat: 40.730206, lng: -73.99662 },
            routeList: {},
            routeList1: {},
            routeList2: {},
            routeList3: {},
            routeList4: {},
            routeList5: {},
            day1: '',
            day2: '',
            dayRef: '',
            loadRoute: '',
            unloadRoute: '',
        }
    },
    created() {
        this.fetchRoutes();
        this.fetchRoutesAll();
        console.log(this.$moment().weekday(1)._d);
    },
    methods: {
        fetchRoutes() {
            this.loading = true;
            axios.get('/api/map/fetchroutelist?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data.success);
                this.routeList = resp.data.success;
              
            });
        },
        fetchRoutesAll() {
            this.loading = true;
            axios.get('/api/map/fetchroutelistall?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data.success);
                this.routeList = resp.data.all;
                this.routeList1 = resp.data.list1;
                this.routeList2 = resp.data.list2;
                this.routeList3 = resp.data.list3;
                this.routeList4 = resp.data.list4;
                this.routeList5 = resp.data.list5;

                this.day1 = resp.data.day1;
                this.day2 = resp.data.day2;
                this.day3 = resp.data.day3;
                this.day4 = resp.data.day4;
                this.day5 = resp.data.day5;
              
            });
        },
        fetchRoutes1() {
            this.loading = true;
            axios.get('/api/map/fetchroutelist1?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data);
                this.day1 = resp.data.day;
                this.routeList1 = resp.data.success;
              
            });
        },
        fetchRoutes2() {
            this.loading = true;
            axios.get('/api/map/fetchroutelist2?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data);
                this.day2 = resp.data.day;
                this.routeList2 = resp.data.success;
              
            });
        },
        fetchRoutes3() {
            this.loading = true;
            axios.get('/api/map/fetchroutelist3?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data);
                this.day3 = resp.data.day;
                this.routeList3 = resp.data.success;
              
            });
        },
        fetchRoutes4() {
            this.loading = true;
            axios.get('/api/map/fetchroutelist4?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data);
                this.day4 = resp.data.day;
                this.routeList4 = resp.data.success;
              
            });
        },
        fetchRoutes5() {
            this.loading = true;
            axios.get('/api/map/fetchroutelist5?query='+localStorage.getItem('access_token')).then(resp => {
                
                console.log(resp.data);
                this.day5 = resp.data.day;
                this.routeList5 = resp.data.success;
              
            });
        },
        dayRefSet(value) {
            console.log(value);
            this.dayRef = value;
        },
        routeClick(data, index) {
            console.log(data);
            this.loadRoute = data.id;
            if(this.dayRef == 'one') {
                
                axios.post('/api/map/addroutelist1/' +data.id).then(resp => {
                    this.routeList1.push(data);
                    this.routeList.splice(index, 1);
                    this.loadRoute = '';
                    this.$toasted.success('Added');
                });
            } else if(this.dayRef == 'two') {
                
                axios.post('/api/map/addroutelist2/' +data.id).then(resp => {
                    this.routeList2.push(data);
                    this.routeList.splice(index, 1);
                    this.loadRoute = '';
                    this.$toasted.success('Added');
                });
            } else if(this.dayRef == 'three') {
                
                axios.post('/api/map/addroutelist3/' +data.id).then(resp => {
                    this.routeList3.push(data);
                    this.routeList.splice(index, 1);
                    this.loadRoute = '';
                    this.$toasted.success('Added');
                });
            } else if(this.dayRef == 'four') {
                
                axios.post('/api/map/addroutelist4/' +data.id).then(resp => {
                    this.routeList4.push(data);
                    this.routeList.splice(index, 1);
                    this.loadRoute = '';
                    this.$toasted.success('Added');
                });
            } else if(this.dayRef == 'five') {
                
                axios.post('/api/map/addroutelist5/' +data.id).then(resp => {
                    this.routeList5.push(data);
                    this.routeList.splice(index, 1);
                    this.loadRoute = '';
                    this.$toasted.success('Added');
                });
            } else {
                console.log('false');
                this.loadRoute = '';
                this.$toasted.error('Please Select Date');
            }
            
        },
        routeRemClick1(data, index) {
            this.unloadRoute = data;
            axios.post('/api/map/remroutelist/' +data).then(resp => {
                
                    this.routeList1.splice(index, 1);
                    this.unloadRoute = '';
                    this.fetchRoutes();
                    this.$toasted.success('Removed');
                   
            });
        },
        routeRemClick2(data, index) {
            axios.post('/api/map/remroutelist/' +data).then(resp => {
                
                    this.routeList2.splice(index, 1);
                    this.fetchRoutes();
                    this.$toasted.success('Removed');
            });
        },
        routeRemClick3(data, index) {
            axios.post('/api/map/remroutelist/' +data).then(resp => {
                
                    this.routeList3.splice(index, 1);
                    this.fetchRoutes();
                    this.$toasted.success('Removed');
            });
        },
        routeRemClick4(data, index) {
            axios.post('/api/map/remroutelist/' +data).then(resp => {
                
                    this.routeList4.splice(index, 1);
                    this.fetchRoutes();
                    this.$toasted.success('Removed');
            });
        },
        routeRemClick5(data, index) {
            axios.post('/api/map/remroutelist/' +data).then(resp => {
                
                    this.routeList5.splice(index, 1);
                    this.fetchRoutes();
                    this.$toasted.success('Removed');
            });
        }

    },
}
</script>
<style>
a[href^="http://maps.google.com/maps"]{display:none !important}

img[src="http://maps.gstatic.com/mapfiles/api-3/images/google_white2.png"]
{display:none}
.gmnoprint a, .gmnoprint span {
    display:none;
}
.gmnoprint div {
    background:none !important;
}
.gmnoprint {
    display:none;
}
#GMapsID div div a div img{
    display:none;
}
.routesListed::-webkit-scrollbar {
  display: none;
} 
.routesListed {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.uil-plus-circle:hover {
    color:#126f1291
}

.selectedDay {
    background-color: #126f1291;
    color: white;
    padding: 1rem;
    margin: 0 -1rem;
}
.selectedDayNot {
    background-color: white;
    padding: 0.8rem;
    margin: 0 -0.8rem;
}
</style>