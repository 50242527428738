<template>
<div class="content">
                    <!-- Topbar Start -->
                    
                    <!-- end Topbar -->

                    <!-- Start Content-->
                    <div class="container-fluid" style="margin-top:-56px;">

                        <!-- start page title -->
                        <div class="row">
                            <div class="col-12">
                                <div class="page-title-box">
                                    <div class="page-title-right">
                                        
                                    </div>
                               
                                </div>
                            </div>
                        </div>
                        <!-- end page title -->

                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <div class="table-responsive">
                                                    <table class="table table-borderless table-centered mb-0">
                                                        <thead class="table-light">
                                                            <tr>
                                                                <th>Product</th>
                                                                <th>Price</th>
                                                                <th>Quantity</th>
                                                                <th>Total</th>
                                                                <th style="width: 50px;"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="i in this.$store.state.cart" :key="i.id">
                                                                <td>
                                                                    <img :src="i.img_src" height="auto" width="50px" style="height:60px;width:100px;object-fit:contain;" alt="contact-img"
                                                                        title="contact-img" class="rounded me-3"  />
                                                                    <p class="m-0 d-inline-block align-middle font-16">
                                                                        <a href="apps-ecommerce-products-details.html"
                                                                            class="text-body">{{i.title}}</a>
                                                                        <br>
                                                                        <small class="me-2"><b>{{i.packaging}}:</b> {{i.size}} </small>
                                                                        
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    {{formatCurrency(i.price)}}
                                                                </td>
                                                                <td>
                                                                    <input type="number" min="1" :value="i.quantity" class="form-control"
                                                                        placeholder="Qty" style="width: 90px;">
                                                                </td>
                                                                <td>
                                                                    {{formatCurrency(i.price * i.quantity)}}
                                                                </td>
                                                                <td>
                                                                    <a href="javascript:void(0);" class="action-icon"> <i class="uil uil-trash-alt"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            
                                                        </tbody>
                                                    </table>
                                                    <table class="mt-2 table mb-0">
                                                <thead class="table-light">
                                                <tr>
                                                    
                                                </tr>
                                                </thead>
                                                <tbody>
                                                
                                                <tr style="border-bottom:0px solid white;">
                                                    <th style="text-align:end;">Total :</th>
                                                    <th style="text-align:end;padding-right:40px;">{{cartTotal}}</th>
                                                </tr>
                                                </tbody>
                                            </table>
                                                </div> <!-- end table-responsive-->

                                                <!-- Add note input-->
                                              

                                                <!-- action buttons-->
                                                <div class="row mt-4">
                                                    <div class="col-sm-6">
                                                        <button @click="$router.go(-1)" class="btn text-muted d-none d-sm-inline-block btn-link fw-semibold">
                                                            <i class="uil uil-arrow-left"></i> Return to Catalog 
                                                        </button>
                                                    </div> <!-- end col -->
                                                    <div class="col-sm-6">
                                                        <div class="text-sm-end">
                                                            <button @click="createOrder" class="btn btn-info">
                                                                <i class="uil uil-plus me-1"></i> Confirm Order 
                                                            </button>
                                                        </div>
                                                    </div> <!-- end col -->
                                                </div> <!-- end row-->
                                            </div>
                                            <!-- end col -->

                                            <div class="col-lg-4">
                                                <div class="border p-3  mt-4 mt-lg-0 rounded">
                                                    <h4 class="header-title mb-3">Shipping Information</h4>

                                                    <div class="table-responsive">
                                                        <table class="table mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <h5>Great Company Long Name</h5>
                                                                </tr>
                                                                <tr>
                                                                    <address class="mt-0 mb-0 font-14 address-lg">
                                                                        795 Folsom Ave, Suite 600<br>
                                                                        San Francisco, CA 94107<br>
                                                                        
                                                                        <div class="pt-2">
                                                                            <abbr title="Phone">P:</abbr> (123) 456-7890 <br/>
                                                                            <abbr title="Mobile">M:</abbr> (+01) 12345 67890
                                                                        </div>
                                                                        
                                                                    </address>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <!-- end table-responsive -->
                                                </div>

                                                <div class="border p-3 rounded" style="margin-top:0.75rem">
                                                    <h4 class="header-title mb-3">Order Details</h4>

                                                    <div class="table-responsive">
                                                        <table class="table mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <ul class="list-unstyled mb-0">
                                                                        <li>
                                                                           
                                                                            <p class="mb-2"><span class="fw-bold me-2">Ordered By:</span> <input v-model="formInput.orderedby" type="text" id="simpleinput" class="form-control"></p>
                                                                            <p class="mb-2"><span class="fw-bold me-2">Sugg. Delivery:</span> <input v-model="formInput.deliverby" class="form-control" id="example-date" type="date" name="date"></p>
                                                                            
                                                                            <p class="mb-0"><span class="fw-bold me-2">Note:</span> 
                                                                            </p>
                                                                            <textarea v-model="formInput.note" class="form-control" id="example-textarea" rows="3"
                                                                                placeholder="Write some note.."></textarea>
                                                                        </li>
                                                                    </ul>
                                                                </tr>
                                                                
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <!-- end table-responsive -->
                                                </div>

                                                

                                            </div> <!-- end col -->
                                            

                                        </div> <!-- end row -->
                                    </div> <!-- end card-body-->
                                </div> <!-- end card-->
                            </div> <!-- end col -->
                        </div>
                        <!-- end row -->

                    </div> <!-- container -->

                </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            formInput: {
                orderedby: '',
                deliverby: '',
                note: '',
            },
        }
    },
    computed: {
        cartTotal() {
                let amount = this.$store.state.cart.reduce((acc, item) => acc + (item.price * item.quantity), 0);
                //this.subTotal = amount;
                //this.formInput.total = parseInt(amount) + parseInt(7000); 
                this.formInput.total = amount;
                return this.formatCurrency(amount);
            }
    },
    methods: {
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        createOrder() {
            if(this.formInput.orderedby != '') {
                axios.post('/api/createorder', {
                    client: this.$store.state.startOrder,
                    cart: this.$store.state.cart,
                    form: this.formInput,
                    rep: localStorage.getItem('access_token'),
                }).then(resp => {
                    console.log(resp);
                    this.$router.push({name: 'order-list'});
                    this.$toasted.success("Order Made");
                });
            } else { 
                this.$toasted.error("Ordered By: Missing");
            }
            
        }
    }
}
</script>